var riot = require('riot');
module.exports = riot.tag2('gp-toast', '<div class="toast {inapp: inapp, active: active, success: type == \'success\', fail: type == \'error\'}" onclick="{dismiss}">{text}</div>', 'gp-toast,[data-is="gp-toast"]{ position: absolute; top: 0; left: 0; bottom: 0; right: 0; pointer-events: none; overflow: hidden; z-index: 110; } gp-toast .toast,[data-is="gp-toast"] .toast{ position: absolute; bottom: 16px; left: 16px; padding: 16px; font-size: 16px; pointer-events: auto; max-width: 400px; border-radius: 6px; background: #888; color: #FFF; box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22); -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; -webkit-transform: translate3d(0, 200%, 0); -moz-transform: translate3d(0, 200%, 0); -ms-transform: translate3d(0, 200%, 0); -o-transform: translate3d(0, 200%, 0); transform: translate3d(0, 200%, 0); -webkit-transition: transform .3s ease; -moz-transition: transform .3s ease; -ms-transition: transform .3s ease; -o-transition: transform .3s ease; transition: transform .3s ease; } gp-toast .toast.inapp,[data-is="gp-toast"] .toast.inapp{ left: 296px; } gp-toast .toast.active,[data-is="gp-toast"] .toast.active{ -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); } gp-toast .toast.success,[data-is="gp-toast"] .toast.success{ background: #00AA00; } gp-toast .toast.fail,[data-is="gp-toast"] .toast.fail{ background: #AA0000; } @media screen and (max-width: 900px) { gp-toast .toast.inapp,[data-is="gp-toast"] .toast.inapp{ left: 16px; } } @media screen and (max-width: 432px) { gp-toast .toast,[data-is="gp-toast"] .toast{ position: absolute; bottom: 0; left: 0; right: 0; padding: 16px; width: 100%; max-width: 100%; } gp-toast .toast.inapp,[data-is="gp-toast"] .toast.inapp{ left: 0; } }', '', function(opts) {

    this.stored   = [];
    this.type     = '';
    this.text     = '';
    this.duration = 3000;
    this.active   = false;
    this.inapp    = false;
    this.timeout  = false;

    this.on('mount', function () {

      app.tags.toast = this;

      app.showToast = function (message, type, duration, forceLeft) {

        this.inapp = opts.inapp && (!forceLeft);

        if (this.active) {
          this.stored.push(app.showToast.bind(this, message, type, duration));
          this.dismiss();
          return;
        }

        this.active     = true;
        this.text       = message;
        this.type       = type || 'notify';
        this.duration   = duration || 3000;
        this.update();

        this.timeout = setTimeout (function () {
          this.dismiss();
        }.bind(this), this.duration);

      }.bind(this);

      app.showErrorToast = function (e) {
        var message = 'Error: ';
        if (e.message) message += e.message;
        else message += e;
        app.showToast(message, 'fail');
      }.bind(this);

    }.bind(this));

    this.dismiss = function () {
      if (this.timeout) clearTimeout(this.timeout);
      this.active = false;
      this.update();

      if (this.stored.length > 0) {
        setTimeout(function () {
          this.stored[0]();
          this.stored.shift();
        }.bind(this), 300);
      }
    }.bind(this);

});