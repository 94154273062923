const defaultClientOptions = {
  streaming: true,
  allAttributesPrivate: true,
};

class MockedLaunchDarklyProject {
  constructor(params) {
    this.ready = false;
    this.clientKey = params.clientKey;
    this.userKey = params.userKey;
    this.flags = {};

    this.user = {
      key: this.userKey,
    };

    this.clientOptions = {
      ...defaultClientOptions,
      ...params.clientOptions,
    };

    this.user = {
      ...(params.userOptions || {}),
      key: this.userKey,
    };

    // Set Callbacks
    if (params.onChange) this.onChange = params.onChange;
    if (params.onReady) this.onReady = params.onReady;

    // Set Flags and FlagCallbacks
    const flagCallbacks = params.flagCallbacks || {};

    Object.keys(params.flags).forEach((flagKey) => {
      this.flags[flagKey] = params.flags[flagKey];

      if (flagCallbacks[flagKey]) {
        this.flagCallbacks[flagKey] = flagCallbacks[flagKey];
      }
    });
  }
}

module.exports = class LaunchDarklyClientMock {
  constructor(params) {
    this.projects = {};
    this.key;

    Object.keys(params).forEach((projectKey) => {
      const projectParams = params[projectKey];
      this.projects[projectKey] = new MockedLaunchDarklyProject(projectParams);
    });
  }
};
