

function Verification() {
  this.route = 'verification/';
}

Verification.prototype.fetchVerification = function (token) {
  return window.Servers.givepoint.getReq(`${this.route + token}?populate=user`);
};

Verification.prototype.emailVerification = function (token) {
  return window.Servers.givepoint.putReq(`${this.route + token}/email`);
};

Verification.prototype.passwordVerification = function (token, password) {
  return window.Servers.givepoint.putReq(`${this.route + token}/password`, password);
};

Verification.prototype.registerVerification = function (token) {
  return window.Servers.givepoint.putReq(`${this.route + token}/register`);
};

Verification.prototype.resendVerification = function (email, type) {
  return window.Servers.givepoint.postReq(`${this.route}resend/${email}/${type}`);
};

module.exports = Verification;
