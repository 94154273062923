const crypto = require('crypto');

// Account

window.loadAccount = function loadAccount(fromLogin = false) {
  if (window.config.logs) console.log('--- scaffold:loadAccount() -----------------------------');

  // Fetch User Account
  window.Account.fetch().bind(this)
    .then(function fetchAccount() {
    // Set Profile
      this.profile = window.Account.profile;


      // Get TOS checks
      const tosLastUpdated = window.Account.appInfo.tosLastUpdated;
      const tosLastRead = window.Account.profile.platform.givepoint.tosLastRead || 0;

      // Get version checks
      const versionLatest = window.Account.appInfo.maxVersion;
      const versionMinimum = window.Account.appInfo.minVersion;

      // Initialize Pusher
      // window.initPusher();

      // Initialize Push Notifications
      // if (window.Push) window.Push.setraceFlags.profileLoaded(true);

      const versionAction = checkVersion(window.config.currentVersion, versionLatest, versionMinimum);

      if (versionAction) {
        switch (versionAction) {
          case 'force':
            app.tags.scaffold.forceUpdate = true;
            this.update();
            break;

          case 'update':
            app.showToast(`Update ${Account.appInfo.maxVersion} available. (Current v${window.config.currentVersion})`, 'notify', 5000);
            break;

          default:
            break;
        }
      }

      if (versionAction === 'force') {
        app.showNotificationDialog({
          title: 'Update Required',
          center: true,
          context: 'forceUpdate',
          locked: true,
        });
        return;
      }

      if (tosLastRead < tosLastUpdated) {
        app.showNotificationDialog({
          title: 'Privacy Policy Changed',
          context: 'privacyPolicy',
          confirm: {
            title: 'I AGREE',
          },
          dismiss: false,
        });
      }
      else if (!fromLogin) privacyCheckPassed(fromLogin);
    })
    .catch((e) => {
    // Check if failed due to being offline
    // window.checkIsOffline();
    // window.showErrorToast(e);

    // Set profile loaded
    // this.runMainController();
      route(buildRoute());
      if (window.config.logs) console.log('Error - ', e);
    });
};

function checkVersion(current, latest, min) {
  if (checkNewerVersion(current.split('.'), min.split('.'))) {
    return 'force';
  }
  // else
  if (checkNewerVersion(current.split('.'), latest.split('.'))) {
    return 'update';
  }
  return false;
}

function checkNewerVersion(v1, v2) {
  for (let i = 0; i < v1.length; ++i) {
    if (Number(v1[i]) < Number(v2[i])) return true;
    else if (Number(v1[i]) > Number(v2[i])) return false;
  }
  return false;
}

window.privacyCheckPassed = function privacyCheckPassed() {
  app.raceFlags.profileLoaded = true;
  app.tags.scaffold.runMainController(null, null, null, null, null);
  app.tags.scaffold.loadCharities();
  app.tags.scaffold.update();
};


window.resetApp = function resetApp() {
  app.page = 'home';
  app.target = [];
  app.pages.home.target = [];
  app.pages.donations.target = [];
  app.pages.wallet.target = [];
  app.pages.settings.target = [];

  app.pages.home.list.clearList();
  app.pages.donations.list.clearList();
  app.pages.recurrings.list.clearList();

  app.pages.charity.charity = false;
  app.pages.campaign.campaign = false;

  app.tags.scaffold.update();
};

window.signin = function signin() {
  if (window.config.logs) console.log('\n--- scaffold: signin() -------------------');
  app.tags.login.reset();
  app.query.overlay = 'login';
  route(buildRoute(), '', false);
};

window.signoutDialog = function signoutDialog() {
  app.showDialog({
    title: 'Sign Out',
    body: 'Are you sure you want to sign out?',
    confirm: {
      title: 'SIGN OUT',
      action: signout,
    },
    dismiss: {
      title: 'CANCEL',
    },
  });
};

window.signout = function () {
  window.Account.reset();
  resetApp();
  route('signout', '', true);
};

// Formats

window.dollarFormat = function dollarFormat(amount, notTDG) {
  let value;
  if (notTDG) {
    amount *= 100; // gotta convert to TDG format...
  }

  if ((amount / 100) % 1 === 0) value = (amount / 100).toFixed(2);
  else value = (amount / 100).toFixed(2);

  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

// Helpers

window.downloadReceipt = function downloadReceipt(item) {
  window.Transaction.downloadReceipt(item._id).bind(this)
    .then((data) => {
      window.fileSaver.saveAs(new Blob([data], { type: 'application/pdf' }), `Givepoint Receipt - ${item.organization.name} (${moment(item.created).format('M-D-YYYY h:mm A')}).pdf`);
      app.showToast('Downloading Receipt...', 'success');
    })
    .catch((e) => {
      console.log('Error -', e);
      app.showErrorToast(e);
    });
};

// Tutorial

window.dismissTutorial = function dismissTutorial(item, item2) {
  if (window.tutorialLockout) return;
  window.tutorialLockout = true;

  item2 = item2 instanceof Event ? false : item2;
  if (!item2) window.Account.profile.tutorial[item] = false;
  else window.Account.profile.tutorial[item][item2] = false;

  window.Account.updateTutorial({ tutorial: window.Account.profile.tutorial }).bind(this)
    .then((data) => {
      window.tutorialLockout = false;
    })
    .catch((e) => {
      if (window.config.logs) console.log('--- scaffold:dismissTutorial(', tutorial, ')------ Error:', e);
      window.tutorialLockout = false;
      app.showErrorToast(e);
    });
};
