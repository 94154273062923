

function Recurring() {

}

Recurring.prototype.fetchRecurring = function (id, params) {
  params = params || {};
  if (!params.populate) params.populate = 'organization,cause,user';
  const query = window.buildQuery(params);
  return window.Servers.givepoint.getReq(`recurringDonation/${id}${query}`);
};

Recurring.prototype.fetchRecurrings = function (params) {
  params = params || {};
  if (!params.populate) params.populate = 'organization,cause,user';
  return window.Servers.givepoint.getReq(`recurringDonations${window.buildQuery(params)}`);
};

Recurring.prototype.updateRecurring = function (id, recurring) {
  return window.Servers.givepoint.putReq(`recurringDonation/${id}`, { recurring: recurring });
};

Recurring.prototype.addRecurring = function (recurring) {
  return window.Servers.givepoint.postReq('recurringDonation', { recurring: recurring });
};

Recurring.prototype.updateRecurringRead = function (id) {
  return window.Servers.givepoint.putReq(`recurringDonation/${id}/markRead`);
};
Recurring.prototype.deleteRecurring = function (id) {
  return window.Servers.givepoint.putReq(`recurringDonation/${id}/stop`);
};

Recurring.prototype.replacePaymentMethod = function (data) {
  return window.Servers.givepoint.putReq('recurringDonations', data);
};


module.exports = Recurring;
