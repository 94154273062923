

const Promise = require('bluebird');

function Token(params) {
  this.route = 'oauth/token';
  this.accessToken = '';
  this.refreshToken = '';
  this.description = '';
  this.server = params.server ? params.server : null;

  if (this.server.hasStorage
    && window.localStorage.refreshToken
    && window.localStorage.accessToken) {
    this.accessToken = window.localStorage.accessToken;
    this.refreshToken = window.localStorage.refreshToken;
  }
}

Token.prototype.parse = function (resp) {
  this.accessToken = resp.access_token;
  this.refreshToken = resp.refresh_token;

  if (this.server.hasStorage) {
    if (this.accessToken) window.localStorage.accessToken = resp.access_token;
    if (this.refreshToken) window.localStorage.refreshToken = resp.refresh_token;
  }
};


/**
 * a thenable method that apply for an access token. using the refresh token
 * @param  {String|undefined} password
 * @param  {Object} options
 * @return {Promise}
 */
Token.prototype.refresh = function () {
  if (this.refreshToken === '') {
    return Promise.reject();
  }

  const data = {
    grant_type: 'refresh_token',
    refresh_token: this.refreshToken,
  };

  return this.server.postReq(this.route, data, {}, true).bind(this)
    .then(function (data) {
      this.parse(data);

      return Promise.resolve(data);
    });
};


/**
 * a thenable method that apply for an access token. If you pass in a password,
 * it will use it, otherwise it will use the refresh token
 * @param  {String|undefined} password
 * @param  {Object} options
 * @return {Promise}
 */
Token.prototype.create = function (email, password) {
  const data = { };

  if (password) {
    data.grant_type = 'password',
    data.username = email,
    data.password = password,
    data.description = this.description;
  }
  else {
    if (this.refreshToken === '') {
      return Promise.reject();
    }

    data.grant_type = 'refresh_token',
    data.refresh_token = this.refreshToken;
  }

  return this.server.postReq(this.route, data, {}, true).bind(this)
    .then(function (data) {
      this.parse(data);

      return Promise.resolve(data);
    });
};


Token.prototype.clear = function () {
  this.accessToken = '';
  this.refreshToken = '';

  if (this.server.hasStorage) {
    delete window.localStorage.accessToken;
    delete window.localStorage.refreshToken;
  }
};

module.exports = Token;
