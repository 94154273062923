var riot = require('riot');
module.exports = riot.tag2('gp-scaffold', '<div class="blurer {blur: app.query.overlay, app.tags.dialog.active}"> <div class="gp-page {logged-in: window.Account.profile.userId}"> <gp-page></gp-page> </div> <gp-mobile-nav if="{window.Account.profile.userId}"></gp-mobile-nav> <div class="scrim {active: app.query.nav}" onclick="{toggleMobileNav}"></div> <div class="gp-nav {active: app.query.nav, logged-in: window.Account.profile.userId}"> <gp-nav></gp-nav> </div> </div> <gp-login active="{app.query.overlay == \'login\'}"></gp-login> <gp-donate active="{app.query.overlay == \'donate\'}"></gp-donate> <gp-recurring-payment-in-use active="{app.query.overlay == \'recurringPaymentMethod\'}"></gp-recurring-payment-in-use> <gp-wallet-overlay active="{app.query.wallet == \'wallet\'}"></gp-wallet-overlay> <gp-tax-receipt-overlay active="{app.query.overlay == \'taxReceipt\'}"></gp-tax-receipt-overlay> <gp-recurring-overlay-parent active="{app.query.overlay == \'addRecurring\'}"></gp-recurring-overlay-parent> <gp-dialog></gp-dialog> <gp-toast inapp="{!app.query.overlay}"></gp-toast> <gp-notification-dialog locked="{versionAction == \'force\'}"></gp-notification-dialog>', 'gp-scaffold,[data-is="gp-scaffold"]{ position: fixed; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden; } gp-scaffold .text-unblur,[data-is="gp-scaffold"] .text-unblur{ -webkit-font-smoothing: subpixel-antialiased; -webkit-transform: scale(2); zoom: 0.5; } @font-face { font-family: NunitoSansBold; src: url(\'fonts/NunitoSans-Bold.woff\'); } @font-face { font-family: NunitoSansSemiBold; src: url(\'fonts/NunitoSans-SemiBold.woff\'); } @font-face { font-family: NunitoSans; src: url(\'fonts/NunitoSans-Regular.woff\'); } @font-face { font-family: RubikLight; src: url(\'fonts/Rubik-Light.woff\'); }', '', function(opts) {


    this.on('mount', function () {

      if (window.config.logs) console.log('\n--- scaffold:onMount() -----------------------');

      app.tags.scaffold = this;

      if (window.localStorage.accessToken) loadAccount();
      else                                  {
        app.raceFlags.profileLoaded = true;
        this.runMainController();
      }

      window.addEventListener(
        'ffChanged',
        function() {
          this.update();
        }.bind(this)
      );

      window.addEventListener(
        'ffReady',
        function() {
          this.update();
        }.bind(this)
      );
    }.bind(this));

    this.runMainController = function (page, query, firstLoad, target, initialized) {
      if (window.config.logs) console.log('\n--- gp-scaffold.runMainController(', page, query, firstLoad, target, ') ------------------');
      app.raceFlags.appLoaded = true;
      if (initialized) this.mainController(page, query, firstLoad, target);
    }.bind(this);

    this.mainController = function (page, query, firstLoad, target) {
      if (window.config.logs) console.log('\n--- gp-scaffold.mainController(', page, query, firstLoad, target, ') ------------------');

      app.page          = page;
      app.query         = query;
      app.target        = app.pages[page].target  = target || false;

      var saveQuery = JSON.parse(JSON.stringify(query));
      if (saveQuery.nav)    delete saveQuery.nav;

      app.pages[page].query = saveQuery;

      if (app.page === 'home' || app.page === 'charity' || app.page === 'campaign') {
        app.pages['home'].page = app.page;
      }

      if (app.drawer && app.drawer !== app.page) {
        app.drawer = app.page;
      }

      if (!app.raceFlags.appLoaded) {
        this.runMainController = this.runMainController.bind(this, page, query, firstLoad, target, true);
        return;
      }

      if (!(page in app.pages)) {
        route('', '', true);
        return;
      }

      if (firstLoad && (query.overlay || query.drawer || query.search || query.nav || query.wallet)) {
        if (query.overlay)  delete query.overlay;
        if (query.search)   delete query.search;
        if (query.nav)      delete query.nav;
        if (query.drawer)   delete query.drawer;
        if (query.wallet)   delete query.wallet;
        route(buildRoute(page, target || null, query), '', true);
        return;
      }

      if (app.raceFlags.profileLoaded) {
        if (this.checkAuthorization()) return;
      }

      this.update();

      if (page === 'home') {

        if (app.target.length === 0) {
          this.loadCharities();
        }

        if (app.target.length === 1) {
          app.pages['home'].list.loadItem(app.target[0]);
        }

        if (app.target.length > 1) {
          app.tags['campaign'].loadCampaign(app.target[0], app.target[1]);
        }
      }

      if (page === 'donations') {
        app.tags.donations.init();
      }

      if (page === 'recurrings') {
        app.tags.recurrings.init();
      }

    }.bind(this);

    this.loadCharities = function () {
      if (window.Account.profile.userId && !app.pages['home'].list.loaded) {
        app.pages['home'].list.loadList();
      }
      else if (!window.Account.profile.userId && !app.tags.page.searching) {
        app.pages['home'].list.clearList();
      }
    }.bind(this);

    this.checkAuthorization = function () {

      if (window.config.logs) console.log('\n--- scaffold:checkAuthorization() ---------------------------');

      if (!window.Account.profile.userId && (app.pages[app.page].login)) {

        if (window.config.logs) console.log('   Authorization failed - Login required to access page');

        app.page    = ''
        app.target  = '';

        route('', '', true);
        return true;
      }

      return false;
    }.bind(this);
});
