module.exports={
  "name": "givepoint-app",
  "version": "2.3.2",
  "private": true,
  "main": "index.js",
  "scripts": {
    "test": "NODE_ENV=development node ./scripts/writeEnvVarsFile.js && jest tests/specs",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run",
    "serve": "NODE_ENV=local gulp serve",
    "serve:test": "NODE_ENV=test gulp serve",
    "build:development": "NODE_ENV=development gulp build",
    "build:staging": "NODE_ENV=staging gulp build",
    "build:demo": "NODE_ENV=demo gulp build",
    "build:production": "NODE_ENV=production gulp build",
    "e2e:setup": "sudo rm -rf tests/e2e/reports ; node selenium-setup && node_modules/.bin/webdriver-manager update",
    "e2e:shutdown:webdriver": "node_modules/.bin/webdriver-manager shutdown --seleniumPort 1234",
    "e2e:start:webdriver": "node_modules/.bin/webdriver-manager start --seleniumPort 1234",
    "test:e2e_headless_dev": "nightwatch --tag account --env 'dev'",
    "test:e2e_local": "nightwatch --tag account --env 'local'",
    "test:browserstack:android": "./node_modules/.bin/nightwatch -c nightwatch.browserstack-conf.js -e android",
    "test:browserstack:chrome": "./node_modules/.bin/nightwatch -c nightwatch.browserstack-conf.js -e chrome",
    "test:browserstack:firefox": "./node_modules/.bin/nightwatch -c nightwatch.browserstack-conf.js -e firefox",
    "test:browserstack:ie": "./node_modules/.bin/nightwatch -c nightwatch.browserstack-conf.js -e ie",
    "test:browserstack:iphone": "./node_modules/.bin/nightwatch -c nightwatch.browserstack-conf.js -e iphone",
    "test:browserstack:safari": "./node_modules/.bin/nightwatch -c nightwatch.browserstack-conf.js -e safari"
  },
  "devDependencies": {
    "@zomaroninc/eslint-config-zomaron": "^1.2.0",
    "babel-jest": "^26.3.0",
    "babel-plugin-transform-object-rest-spread": "^6.26.0",
    "babel-preset-env": "^1.7.0",
    "babel-preset-es2015-riot": "^1.1.0",
    "babelify": "^7.3.0",
    "chromedriver": "74.0.0",
    "dotenv": "^8.2.0",
    "eslint": "^4.19.1",
    "eslint-plugin-riot": "^0.1.8",
    "faker": "^4.1.0",
    "gulp-all": "^1.1.0",
    "gulp-header": "^2.0.9",
    "jest": "^26.4.1",
    "jest-junit": "^3.7.0",
    "jest-junit-reporter": "^1.1.0",
    "nightwatch": "^1.6.4",
    "node-sass": "^4.14.1",
    "npm": "^6.14.13",
    "puppeteer": "^1.16.0",
    "randomstring": "^1.1.5",
    "riot-jest-transformer": "^1.0.10",
    "selenium-server": "^3.141.59",
    "webdriver-manager": "12.1.4"
  },
  "engines": {
    "node": ">=12.0.0"
  },
  "dependencies": {
    "@types/node": "^10.17.28",
    "@zomaroninc/zcli": "^1.0.3",
    "bluebird": "^3.7.2",
    "blueimp-load-image-npm": "^0.1.7",
    "browser-sync": "^2.26.12",
    "browserify": "^13.0.0",
    "browserstack-automate": "^1.0.2",
    "browserstack-local": "^1.4.5",
    "countup.js": "^1.8.4",
    "del": "^2.2.0",
    "fastclick": "^1.0.6",
    "file-saver": "^2.0.2",
    "gulp": "^4.0.2",
    "gulp-clean-css": "^4.3.0",
    "gulp-htmlmin": "^5.0.1",
    "gulp-imagemin": "^7.1.0",
    "gulp-minify-html": "^1.0.6",
    "gulp-remove-code": "^3.0.4",
    "gulp-rename": "^2.0.0",
    "gulp-replace": "^1.0.0",
    "gulp-rev": "^9.0.0",
    "gulp-rev-replace": "^0.4.3",
    "gulp-size": "^3.0.0",
    "gulp-uglify": "^3.0.2",
    "gulp-usemin": "^0.3.30",
    "i18n-iso-countries": "^5.5.0",
    "jstz": "^1.0.11",
    "launchdarkly-js-client-sdk": "^2.18.0",
    "lodash": "^4.17.21",
    "merge-stream": "^1.0.0",
    "moment": "^2.27.0",
    "mongoose": "^5.10.0",
    "nouislider": "^8.5.1",
    "photoswipe": "^4.1.3",
    "pug": "^3.0.2",
    "riot": "^3.13.2",
    "riot-route": "^3.1.4",
    "riotify": "^2.0.0",
    "riotjs": "^1.0.4",
    "selenium-download": "^2.0.14",
    "selenium-webdriver": "^4.0.0-alpha.7",
    "sift": "^3.2.1",
    "superagent": "^3.3.2",
    "swiper": "^6.6.1",
    "vinyl-source-stream": "^2.0.0"
  },
  "jest": {
    "verbose": false,
    "automock": false,
    "browser": true,
    "coverageDirectory": "./coverage/",
    "collectCoverage": true,
    "collectCoverageFrom": [
      "app/**/*.js"
    ],
    "coverageReporters": [
      "cobertura"
    ],
    "setupFiles": [
      "<rootDir>/tests/jest.setup.js"
    ],
    "testPathIgnorePatterns": [
      "<rootDir>/cypress/"
    ],
    "testURL": "http://localhost/",
    "transform": {
      "^.+\\.jsx?$": "babel-jest",
      "\\.(tag)$": "riot-jest-transformer"
    },
    "testResultsProcessor": "./node_modules/jest-junit-reporter"
  },
  "prettier": {
    "trailingComma": "all",
    "arrowParens": "always",
    "useTabs": false,
    "bracketSpacing": true,
    "tabWidth": 2,
    "semi": true,
    "singleQuote": true
  }
}
