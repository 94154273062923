const ServerError = function(errorData, statusCode) {
  this.code = errorData.code;
  this.statusCode = statusCode;
  this.message = errorData.message;
  this.type = this.name = this.codeNameForCode(this.code);
  if (errorData.moreinfo) this.moreInfo = this.moreInfo = errorData.moreinfo;

  this.stack = new Error().stack;
};

// INHERITANCE
ServerError.prototype = Object.create(Error.prototype);

// CODES
ServerError.prototype.CODES = {
  REFRESH_TOKEN_NOT_FOUND: 40010,
  ACCESS_TOKEN_NOT_FOUND: 40011,
  ACCESS_TOKEN_EXPIRED: 40012,
};

ServerError.prototype.codeNameForCode = function(code) {
  for (const errCode in ServerError.CODES) {
    if (code === ServerError.CODES[errCode]) return errCode;
  }

  return null;
};

module.exports = ServerError;
