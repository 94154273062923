import packageJson from '../../package.json';
import moment from 'moment';
import envVars from '../../env.json';

function Config(brandName = envVars.PAYSTONE_BRAND_NAME) {
  this.currentVersion = packageJson.version;
  this.SERVE = envVars.SERVE;

  console.log(
    `Givepoint v${this.currentVersion}  ---  Updated ${moment(
      window.buildTime,
    ).format('MMMM DD, YYYY - h:mm A')}`,
  );

  this.BRAND_NAME = brandName;

  this.API = {
    ACCOUNT: envVars.API_ACCOUNT,
    GIVEPOINT: envVars.API_GIVEPOINT,
    MEDIA: envVars.API_MEDIA,
  };

  this.URL = {
    GIVEPOINT: envVars.URL_GIVEPOINT,
    ACCOUNT: envVars.URL_PAYSTONE_ACCOUNT,
    ADMIN: envVars.URL_PAYSTONE_ADMIN,
    HUB: envVars.URL_PAYSTONE_HUB,
    HOMEPAGE: envVars.URL_PAYSTONE_HOMEPAGE,
  };

  this.URL.FEEDBACK = envVars.URL_FEEDBACK;
  this.URL.ABOUT = `${this.URL.HOMEPAGE}fundraising`;
  this.URL.CONTACT_SUPPORT = `${this.URL.HOMEPAGE}contact`;
  this.URL.PRIVACY_POLICY = `${this.URL.HOMEPAGE}privacy`;
  this.URL.TOS = `${this.URL.HOMEPAGE}legal/givepoint-terms-and-conditions`;

  this.MEDIA = envVars.API_MEDIA_DOWNLOAD;

  this.ACCOUNT_KEY = envVars.ACCOUNT_PLATFORM_KEY;
  this.PUSHER_KEY = envVars.PUSHER_KEY;

  this.logs = envVars.ENVIRONMENT === 'development';
  this.isTest = envVars.TEST;
}

module.exports = Config;
