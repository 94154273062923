const Promise = require("bluebird");

function Account(params) {
  this.appInfo = {};

  this.route = "account";
  this.profile = {
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    address: {},
    userId: false,
    organizations: [],
    cards: {
      wallet: [],
      linked: []
    },
    options: {},
    color: {},
    tutorial: {
      cause: false,
      charities: false,
      myCharities: false,
      donations: false,
      wallet: {
        walletCard: false,
        linkedCard: false,
        billing: false
      },
      recurring: {
        empty: false,
        active: false
      }
    },
    notificationSettings: {},
    timeZone: "",
    paymentMethods: [],
    platform: {},
    paymentProfile: {},
    hideTutorial: false,
    created: 0
  };

  this.tutorialObject = {
    cause: true,
    charities: true,
    myCharities: true,
    donations: true,
    wallet: {
      walletCard: true,
      linkedCard: true,
      billing: true
    },
    recurring: {
      empty: true,
      active: true
    }
  };
}

Account.prototype.create = function(user, token) {
  const tokenString = token ? `/token/${token}` : "";
  return window.Servers.account.postReq(this.route + tokenString, user);
};

Account.prototype.register = function(email) {
  return window.Servers.account.postReq(`${this.route}/email/${email}`);
};

Account.prototype.updateTutorial = function(tutorial) {
  return window.Servers.account.putReq(`${this.route}/tutorial`, tutorial);
};

Account.prototype.fetch = function() {
  return window.Servers.account
    .getReq("platform")
    .bind(this)
    .then(function(data) {
      this.appInfo = data;
      return window.Servers.givepoint.getReq(this.route).bind(this);
    })
    .then(function(data) {
      this.profile.firstName = data.firstName;
      this.profile.lastName = data.lastName;
      this.profile.fullName = data.fullName;
      this.profile.phone = data.phone;
      this.profile.email = data.email;
      this.profile.organizations = data.organizations;
      this.profile.userId = data._id;
      this.profile.created = data.created;
      this.profile.color = data.color;
      this.profile.paymentMethods = data.paymentMethods;
      this.profile.hideTutorial = data.hideTutorial;
      this.profile.paymentProfile = data.paymentProfile || {};
      this.profile.platform = data.platform;
      this.profile.recurringNotificationCount = 0;

      const wallet = [];
      const linked = [];

      if (data.paymentMethods && data.paymentMethods.length > 0) {
        for (let i = 0; i < data.paymentMethods.length; ++i) {
          if (data.paymentMethods[i].profile)
            wallet.push(data.paymentMethods[i]);
          else linked.push(data.paymentMethods[i]);
        }
      }

      if (data.platform.givepoint.recurringNotificationCount)
        this.profile.recurringNotificationCount =
          data.platform.givepoint.recurringNotificationCount;

      this.profile.cards = {
        wallet: wallet,
        linked: linked
      };

      if (data.address) this.profile.address = data.address;
      if (data.tutorial) this.profile.tutorial = data.tutorial;

      if (window.config.logs)
        console.log("\n--- Account:fetch() ------", this.profile);

      // this.profile.timeZone               = data.timeZone;
      return Promise.resolve(data);
    });
};

Account.prototype.reset = function() {
  this.profile = {
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    address: {},
    userId: false,
    organizations: [],
    cards: {
      wallet: [],
      linked: []
    },
    options: {},
    platform: {},
    color: {},
    notificationSettings: {},
    timeZone: "",
    paymentMethods: [],
    tutorial: {
      cause: false,
      charities: false,
      myCharities: false,
      donations: false,
      wallet: {
        walletCard: false,
        linkedCard: false,
        billing: false
      },
      recurring: {
        empty: false,
        active: false
      }
    },
    paymentProfile: {},
    hideTutorial: false,
    created: 0
  };
};

Account.prototype.fetchDonationOrganizations = function() {
  return window.Servers.givepoint.getReq(
    "donations/organizations?isApproved=true&isFunded=true&isCharitable=true"
  );
};

Account.prototype.fetchDonationKiosks = function(organization) {
  organization = organization || null;

  let params = "";
  if (organization) params = `?organization=${organization}`;

  return window.Servers.givepoint.getReq(`donations/kiosks${params}`);
};

Account.prototype.update = function(data) {
  return window.Servers.givepoint
    .putReq(this.route, data)
    .bind(this)
    .then(function(data) {
      if (data.firstName) this.profile.firstName = data.firstName;
      if (data.lastName) this.profile.lastName = data.lastName;
      if (data.fullName) this.profile.fullName = data.fullName;
      if (data.phone) this.profile.phone = data.phone;
      if (data.email) this.profile.email = data.email;
      if (data.address) this.profile.address = data.address;
      if (data.organizations) this.profile.organizations = data.organizations;
      if (data._id) this.profile.userId = data._id;
      if (data.created) this.profile.created = data.created;
      if (data.color) this.profile.color = data.color;
      if (data.paymentMethods)
        this.profile.paymentMethods = data.paymentMethods;
      if (data.paymentProfile)
        this.profile.paymentProfile = data.paymentProfile;
      if (data.hideTutorial) this.profile.hideTutorial = data.hideTutorial;
      if (data.platform) this.profile.platform = data.platform;

      this.profile.userId = data._id;
    })
    .catch(e => {
      if (window.config.logs) console.log(e);
    });
};

Account.prototype.updatePrivacyPolicy = function() {
  return window.Servers.account.putReq(`${this.route}/tos`);
};

Account.prototype.updateBilling = function(data) {
  return window.Servers.givepoint.putReq(`${this.route}/billing`, data);
};

Account.prototype.passwordForgot = function(data) {
  return window.Servers.account.postReq(`${this.route}/password/forgot`, data);
};

Account.prototype.changePassword = function(data) {
  return window.Servers.account.putReq(this.route, data);
};

Account.prototype.changeEmail = function(data) {
  return window.Servers.account.postReq(`${this.route}/email`, data);
};

Account.prototype.registerPush = function(token) {
  return window.Servers.givepoint.postReq(`${this.route}/push/register`, token);
};

Account.prototype.unregisterPush = function(token) {
  return window.Servers.givepoint.postReq(
    `${this.route}/push/unregister`,
    token
  );
};

Account.prototype.addPaymentMethod = function(data) {
  return window.Servers.givepoint.postReq("paymentMethod", data);
};

Account.prototype.removePaymentMethod = function(paymentMethodId, params) {
  params = params || {};
  return window.Servers.givepoint.delReq(
    `paymentMethod/${paymentMethodId}${window.buildQuery(params)}`
  );
};

Account.prototype.addPaymentMethodWithProfile = function(profileId, data) {
  return window.Servers.givepoint.postReq(
    `paymentProfile/${profileId}/paymentMethod`,
    data
  );
};

Account.prototype.updatePaymentProfile = function(data) {
  return window.Servers.givepoint.putReq(`${this.route}/billing`, data);
};

Account.prototype.checkPaymentMethod = function(hash) {
  return window.Servers.givepoint.getReq(`paymentMethod/hash/${hash}/exists`);
};

Account.prototype.hideTutorial = function() {
  return window.Servers.givepoint
    .putReq(`${this.route}/tutorial/hide`)
    .bind(this)
    .then(function(data) {
      this.profile.hideTutorial = true;
    });
};

module.exports = Account;
