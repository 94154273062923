window.countries = require('i18n-iso-countries');
const en = require('i18n-iso-countries/langs/en.json');
// these are used to get more detailed country data for countries we commonly support
const customCountries = require('./countries');

window.countries.registerLocale(en);

function findByNameOrAbbreviation(list, searchString) {
  return list.find(
    (li) => li.abbreviation === searchString || li.name === searchString,
  );
}

/**
 * @description Searches for province list if available for supplied country
 * @param  {} country can be country name or 2 char abbreviation
 * @param  {} lang=en only english is supported, since this is our custom country list
 */
window.countries.getProvinces = function(country, lang = en) {
  let retVal = [];
  const foundCountry = findByNameOrAbbreviation(customCountries, country);
  if (foundCountry) {
    retVal = foundCountry.provinces;
  }
  return retVal;
};

/**
 * @description Searches for province details if available for supplied country, and province
 * @param  {} country can be country name or 2 char abbreviation
 * @param  {} province can be province name or 2 char abbreviation
 * @param  {} lang=en only english is supported, since this is our custom country list
 */
window.countries.getProvince = function(country, province, lang = en) {
  let retVal = {};
  const foundCountry = findByNameOrAbbreviation(customCountries, country);
  if (foundCountry) {
    const foundProvince = findByNameOrAbbreviation(
      foundCountry.provinces,
      province,
    );

    retVal = foundProvince;
  }
  return retVal;
};

/**
 * @description Searches for province name if available for supplied country, and province
 * @param  {} country can be country name or 2 char abbreviation
 * @param  {} province can be province name or 2 char abbreviation
 * @param  {} lang=en only english is supported, since this is our custom country list
 */
window.countries.getProvinceName = function(country, province, lang = en) {
  let retVal = province;
  const provinceDetails = window.countries.getProvince(country, province, lang);
  if (provinceDetails.name) {
    retVal = provinceDetails.name;
  }
  return retVal;
};

/**
 * @description Searches for province name if available for supplied country, and province
 * @param  {} country can be country name or 2 char abbreviation
 * @param  {} province can be province name or 2 char abbreviation
 * @param  {} lang=en only english is supported, since this is our custom country list
 */
window.countries.getProvinceAlpha2Code = function(
  country,
  province,
  lang = en,
) {
  let retVal = province;
  const provinceDetails = window.countries.getProvince(country, province, lang);
  if (provinceDetails.name) {
    retVal = provinceDetails.abbreviation;
  }
  return retVal;
};

/**
 * @description Searches for country name if available for supplied country
 * @param  {} country can be country name or 2 char abbreviation
 * @param  {} lang=en only english is supported, since this is our custom country list
 */
window.countries.getCountryAlpha2Code = function(country, lang = en) {
  let retVal = country;
  const countryName = window.countries.getAlpha2Code(country, lang);
  if (countryName) {
    retVal = countryName;
  }
  return retVal;
};

/**
 * @description Searches for postal regex if available for supplied country
 * @param  {} country can be country name or 2 char abbreviation
 * @param  {} lang=en only english is supported, since this is our custom country list
 */
window.countries.getPostalRegex = function(country, lang = en) {
  let retVal = /.*/i;
  const foundCountry = findByNameOrAbbreviation(customCountries, country);
  if (foundCountry) {
    retVal = foundCountry.postalRegex;
  }
  return retVal;
};
