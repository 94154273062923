function List(params) {
  params = params || {};

  this.lockout = false;

  // PARAMS
  this.name = params.name || '';
  this.items = params.items || [];
  this.selected = params.selected || {};
  this.sort = params.sort || '';
  this.filter = params.filter || {};
  this.noMoreItems = params.noMoreItems || false;
  this.loadItemKey = params.loadItemKey || 'id';
  this.loaded = false;

  // FUNCTIONS
  this.loadListFunction = params.loadListFunction || null;
  this.loadItemFunction = params.loadItemFunction || null;
  this.onItemOpened = params.onItemOpened || null;
  this.onItemSelected = params.onItemSelected || null;
  this.loadItemError = params.loadItemError || null;
  this.scope = params.scope || null;

  this.onListLoaded = params.onListLoaded || null;

  this.limit = 25;
  if (window.config.logs) console.log('\n--- list:List()', this);
}

List.prototype.loadList = function (before, filter, sort) {
  if (this.lockout) return;
  this.lockout = true;

  if (filter) this.filter = filter;
  if (sort) this.sort = sort;
  if (this.scope) this.scope.update();

  before = before || false;

  // CHECK IF THERE IS AN OFFSET (REQUIRED FOR SORT)
  let offset = 0;
  if (before) offset = this.items.length;

  // INIT PARAMS
  const params = {
    limit: this.limit,
    offset: offset,
  };

  if (!before) {
    this.items = [];
    this.noMoreItems = false;
    if (this.scope) this.scope.update();
  }

  // FILTERS
  for (let key in this.filter) {
    params[key] = this.filter[key];
  }

  // SORTS
  if (this.sort) params.sort = this.sort;

  this.loadListFunction(params)
    .then((data) => {
    // REVERSE DATA IF REQUIRED BY SORT
      if (this.sort && this.sort.charAt(0) !== '-') {
        data.reverse();
      }

      // ADD UNITS TO LIST
      const items = JSON.parse(JSON.stringify(this.items));

      let found;
      for (let i = 0; i < data.length; ++i) {
        found = false;
        for (let j = 0; j < items.length; ++j) {
          if (data[i]._id === items[j]._id) {
            found = true;
            break;
          }
        }
        if (!found) items.push(data[i]);
      }

      this.items = items;
      this.loaded = true;

      this.noMoreItems = (data.length < params.limit);
      this.lockout = false;
      if (this.scope) this.scope.update();

      if (this.onListLoaded) { this.onListLoaded(); }
    })
    .catch((e) => {
      if (window.config.logs) console.log('\n--- List:loadList - Error:', e);
      app.showErrorToast(e);
      this.lockout = false;
    });
};

List.prototype.clearList = function () {
  this.items = [];
  this.loaded = false;
  this.selected = {};
  this.filter = {};
  this.sort = '';
  this.lockout = false;
  this.noMoreItems = false;

  if (this.scope) this.scope.update();
};

List.prototype.listScrolled = function (e) {
  if (this.noMoreItems || this.items.length === 0) return;

  if (e.srcElement.scrollTop + e.srcElement.clientHeight + 110 >= e.srcElement.scrollHeight) this.loadList(this.items[this.items.length - 1]._id);
};

List.prototype.loadMore = function () {
  this.loadList(this.items[this.items.length - 1]._id);
};

List.prototype.selectItem = function (item) {
  if (window.config.logs) console.log('--- list:selectItem(', item, ')', this);

  this.openItem(item);
  if (this.onItemSelected) this.onItemSelected(item);

  // ITEM ALREADY LOADED
  if (this.selected && this.selected._id === item._id) return;
};

List.prototype.openItem = function (item) {
  // ITEM ALREADY LOADED
  if (this.selected && this.selected._id === item) return;

  this.selected = item;

  if (this.scope) this.scope.update();
  if (this.onItemOpened) this.onItemOpened(item);
};

List.prototype.updateItem = function (item) {
  for (let i = 0; i < this.items.length; ++i) {
    if (item._id === this.items[i]._id) {
      this.items[i] = item;
      if (this.scope) this.scope.update();
      break;
    }
  }
};

List.prototype.removeItem = function (item) {
  for (let i = 0; i < this.items.length; ++i) {
    if (item._id === this.items[i]._id) {
      this.items.splice(i, 1);
      if (!this.noMoreItems && this.items.length < this.limit) this.loadList(this.items[this.items.length - 1]._id);
      else if (this.items.length === 0) {
        this.noMoreItems = true;
      }
      if (this.scope) this.scope.update();
      break;
    }
  }
};

List.prototype.loadItem = function (loadKey) {
  if (window.config.logs) console.log('\n---list:loadItem(', loadKey, ') ---------', this);

  // Check if can load item
  if (!this.loadItemFunction) return;

  // Check if Item is already loaded
  if (this.selected && this.selected[this.loadItemKey] === loadKey) return;

  // Check if item is already in the List
  for (let i = 0; i < this.items.length; ++i) {
    if (this.items[i][this.loadItemKey] === loadKey) {
      this.openItem(this.items[i]);
      return;
    }
  }

  this.loadItemFunction(loadKey)
    .then((data) => {
      this.openItem(data);
    })
    .catch((e) => {
      if (this.loadItemError) this.loadItemError(e);
    });
};

module.exports = List;
