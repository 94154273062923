

function Cause() {

}

Cause.prototype.fetchCauses = function (params) {
  const query = params || {};

  query.populate = query.populate || 'organization';
  if (params.organizations) query.organization = params.organizations;
  return window.Servers.givepoint.getReq(`causes/public${window.buildQuery(query)}`);
};

Cause.prototype.fetchCause = function (id, params) {
  const query = params || {};
  query.populate = query.populate || 'organization';
  return window.Servers.givepoint.getReq(`cause/${id}/public`, window.buildQuery(query));
};

Cause.prototype.fetchCauseUrl = function (orgKey, causeKey, params) {
  const query = params || {};
  query.populate = query.populate || 'organization';
  return window.Servers.givepoint.getReq(`organization/${orgKey}/cause/${causeKey}${window.buildQuery(query)}`);
};

module.exports = Cause;
