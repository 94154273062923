function Organization() {}

Organization.prototype.fetchOrganizations = function(params) {
  params = params || {};
  if (!params.populate) params.populate = "cause";
  if (window.Account.profile.userId) params.following = true;
  params.hasOnlineMID = true;
  const query = window.buildQuery(params);
  return window.Servers.givepoint.getReq(`organizations${query}`);
};

Organization.prototype.fetchOrganization = function(id, params) {
  params = params || {};
  if (!params.populate) params.populate = "cause";
  if (window.Account.profile.userId) params.following = true;
  params.hasOnlineMID = true;
  const query = window.buildQuery(params);
  return window.Servers.givepoint.getReq(`organization/${id}${query}`);
};

Organization.prototype.fetchOrganizationUrl = function(name, params) {
  params = params || {};
  if (!params.populate) params.populate = "cause";
  if (window.Account.profile.userId) params.following = true;
  params.hasOnlineMID = true;
  const query = window.buildQuery(params);
  return window.Servers.givepoint.getReq(`organization/url/${name}${query}`);
};

Organization.prototype.fetchOrganizationTaxYears = function(id, params) {
  params = params || {};
  if (window.Account.profile.userId) {
    params.user = window.Account.profile.userId;
  }
  const query = window.buildQuery(params);
  return window.Servers.givepoint.getReq(`organization/${id}/taxYears${query}`);
};

Organization.prototype.fetchOrganizationSearchOrFollowed = function(params) {
  params = params || {};
  if (!params.populate) params.populate = "cause";
  if (window.Account.profile.userId && !params.skipFollowing) {
    params.following = true;
  }
  if (params.skipFollowing) {
    delete params.skipFollowing;
  }

  // if (params.search) params.hasMid = true;
  params.hasOnlineMID = true;
  const query = window.buildQuery(params);

  if (params.search)
    return window.Servers.givepoint.getReq(`organizations${query}`);
  return window.Servers.givepoint.getReq(`organizations/following${query}`);
  // else if (params.following) return window.Servers.account.getReq('organizations/following' + query);
  // else {
  //   return new Promise(function (resolve, reject) {
  //     return resolve([]);
  //   });
  // }
};

Organization.prototype.follow = function(id) {
  return window.Servers.givepoint.postReq(`organization/${id}/follow`);
};

Organization.prototype.unfollow = function(id) {
  return window.Servers.givepoint.delReq(`organization/${id}/unfollow`);
};

module.exports = Organization;
