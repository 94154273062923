const riot = require('riot');

let scaffold;
if (window.config.SERVE) {
  route.base('#!/');
} else {
  route.base('/');
}

route.start(true);

route.parser((path) => {
  let route = path.split('?')[0];
  if (route[route.length - 1] === '/') route = route.slice(0, -1);
  return route;
});

// Main Routing Function

route((target, action, params) => {
  if (window.config.logs)
    console.log(
      '\n--- app.js route(',
      target,
      action,
      params,
      ') -----------------',
    );

  // Check Signout
  if (target === 'signout') {
    window.Servers.account.clear();
    return route('home', '', true);
  }

  // Ensure Route exists
  if (!target) return route('home', '', true);

  target = target.split('/');

  let page;
  if (target[0] in app.pages) {
    page = target[0];
    target.shift();
  } else {
    page = 'home';
  }

  if (scaffold) {
    scaffold.mainController(page, route.query(), false, target);
    scaffold.update();
  } else {
    scaffold = riot.mount('body', 'gp-scaffold')[0];
    scaffold.mainController(page, route.query(), true, target);
  }
});

// Route Builders

window.buildRoute = function(page, target, query, ignoreQuery) {
  if (window.config.logs)
    console.log(
      '\n--- app:buildRoute(',
      page,
      target,
      query,
      ignoreQuery,
      ')------------------',
    );

  page = page || app.page;
  target = target || app.target;
  query = query || app.query;

  if (page === 'home') {
    page = target.length === 0 ? 'home' : '';
  }

  target = parseTarget(target);
  ignoreQuery = ignoreQuery || [];

  return page + target + buildQuery(query, ignoreQuery);
};

function parseTarget(target) {
  if (!target) return '';

  if (!(target instanceof Array)) target = [target];

  let targetString = '';
  for (let i = 0; i < target.length; ++i) {
    targetString += `/${target[i]}`;
  }
  return targetString;
}

window.buildQuery = function(object, ignore) {
  let query = '';
  var ignore;

  for (const key in object) {
    ignore = false;
    for (let i = 0; i < ignore.length; ++i)
      if (ignore[i] === key) ignore = true;
    if (!ignore) query += `&${key}=${object[key]}`;
  }

  // Replace first & with ? (for proper query)
  if (query.length > 0) query = `?${query.substr(1)}`;
  return query;
};

// Navigation Helpers

window.openCampaign = function(campaign, organization) {
  if ((campaign.isDeleted && campaign.isDeleted > 0) || !campaign.public) {
    app.showToast('That campaign is no longer active.', 'notify');
    return;
  }

  app.page = 'home';
  app.target = [
    organization.platform.givepoint.friendlyUrl,
    campaign.friendlyUrl,
  ];
  delete app.query.drawer;
  route(buildRoute());
};

window.openCharity = function(organization) {
  if (organization.isDeleted && organization.isDeleted > 0) {
    app.showToast('That charity is no longer available.', 'notify');
    return;
  }

  app.page = 'home';
  app.target = [organization.platform.givepoint.friendlyUrl];
  delete app.query.drawer;
  route(buildRoute());
};

window.openSiteLink = (item) => {
  if (window.config.logs)
    console.log('\n--- scaffold: openSiteLink(', item, ') -------------------');
  window.open(window.config.URL[item.url], '_blank');
};

window.openNav = function(page, target) {
  target = target instanceof String || target instanceof Array ? target : false;

  app.page = page;

  if (target) target = app.target = target;
  else target = app.pages[page].target;
  app.target = target;

  route(buildRoute(page, target, app.pages[page].query), '', false);
};

window.openDrawer = function(page, option) {
  if (window.config.logs)
    console.log('\n--- openDrawer(', page, option, ') ---------------------');

  const drawer = page;
  app.drawer = drawer;

  app.tags[`${app.drawer}-drawer`].reset(option);
  if (app.tags[`${app.drawer}-drawer`].init)
    app.tags[`${app.drawer}-drawer`].init();
  addQuery('drawer', option);
};

window.addQuery = function(query, value) {
  if (window.config.logs)
    console.log('\n--- addQuery(', query, value, ') ---------------------');
  app.query[query] = value;
  route(buildRoute(), '', false);
};

window.toggleQuery = function(query, value) {
  if (window.config.logs)
    console.log('\n--- toggleQuery(', query, value, ') ---------------------');
  if (query in app.query) delete app.query[query];
  else app.query[query] = value;
  route(buildRoute(), '', false);
};

window.toggleMobileNav = function() {
  if (app.query.nav) {
    delete app.query.nav;
  } else if (app.page === 'home' && !!app.target && app.target.length == 1) {
    app.page = 'home';
    app.target = false;
  } else if (app.page === 'home' && !!app.target && app.target.length == 2) {
    app.page = 'home';
    app.target = [app.target[0]];
  } else {
    app.query.nav = true;
  }

  route(buildRoute(), '', true);
};

window.closeOverlay = function() {
  delete app.query.overlay;
  route(buildRoute(), '', true);
};

window.removeQuery = function(query, replace) {
  replace = replace || false;
  if (query in app.query) {
    delete app.query[query];
    route(buildRoute(), '', replace);
  }
};
