module.exports = {
  pages: {
    home: {
      nav: true,
      name: 'Home',
      icon: 'home',
      page: 'home',
      target: false,
      query: {},
    },

    charity: {
      name: 'Charity',
      charity: {},
      target: false,
      query: {},
    },

    campaign: {
      name: 'Campaign',
      campaign: {},
      target: false,
      query: {},
    },

    donate: {
      name: 'Donate',
      campaign: {},
      target: false,
      query: {},
    },

    receipt: {
      name: 'Tax Receipt',
      campaign: {},
      target: false,
      query: {},
    },

    donations: {
      nav: true,
      login: true,
      name: 'Donations',
      icon: 'donations',
      target: false,
      query: {},
      list: null, // Initialized by gp-donations
      sorts: {
        date: {
          name: 'Date',
          value: '-1',
          mobile: true,
        },
        amount: {
          name: 'Amount',
          value: '-1',
          mobile: true,
        },
        source: {
          name: 'Source',
          value: '-1',
          mobile: true,
        },
        type: {
          name: 'Type',
          value: '-1',
          mobile: true,
        },
      },
    },

    recurrings: {
      nav: true,
      name: 'Recurring',
      login: true,
      icon: 'recurrings',
      target: false,
      query: {},
      badge: true,
      list: null, // Initialized by gp-recurrings
      sorts: {
        amount: {
          name: 'Amount',
          value: '-1',
          mobile: true,
        },
        status: {
          name: 'Status',
          value: '1',
          field: 'state.status',
          mobile: true,
        },
        firstPayment: {
          name: 'First Payment',
          field: 'created',
          value: -1,
        },
        nextPayment: {
          name: 'Next Payment',
          field: 'nextBilling',
          value: -1,
        },
        finalPayment: {
          name: 'Final Payment',
          field: 'endDate',
          value: -1,
        },
      },
    },

    wallet: {
      nav: true,
      login: true,
      name: 'Wallet',
      icon: 'wallet',
      target: false,
      query: {},
    },

    settings: {
      login: true,
      name: 'Settings',
      icon: 'settings',
      target: false,
      query: {},
    },
  },

  page: 'home',
  target: '',

  siteLinks: {
    about: {
      name: 'About',
      url: 'ABOUT',
      icon: 'information-outline.svg',
    },
    contact: {
      name: 'Contact',
      url: 'CONTACT_SUPPORT',
      icon: 'email-outline.svg',
      account: true,
    },
    feedback: {
      name: 'Feedback',
      url: 'FEEDBACK',
      icon: 'feedback-icon.svg',
    },
    privacyPolicy: {
      name: 'Privacy Policy',
      url: 'PRIVACY_POLICY',
      icon: 'shield-half-full.svg',
      account: true,
    },
    termsOfUse: {
      name: 'Terms of Use',
      url: 'TOS',
      icon: 'clipboard-text.svg',
      account: true,
    },
  },

  drawer: '',
  tags: {},
  query: {},

  raceFlags: {
    appLoaded: false,
  },

  // CONSTANTS
  DATE_FORMATS: {
    day: 'MMMM DD, YYYY',
    dayShortMonth: 'MMM DD, YYYY',
    charity_receipt: 'MMMM D',
    dayMonthShort: 'MMM D',
  },

  REGEX: {
    NINE_DIGIT_DOLLAR: /^\d{0,9}$|^\d{0,9}\.\d{0,2}$/,
    POSTAL_CODE: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    CARD_NUMBER: /^[0-9]{13,19}$/,
  },

  errors: {
    99999: 'Unknown error occured.',
  },

  // FUNCTIONS
  dollarFormat: null,
  openDrawer: null,
  showToast: null,
  showErrorToast: null,
  showDialog: null,

  // Recurring Frequencies
  recurringFrequency: {
    ONE_TIME: 0,
    DAILY: 1,
    WEEKLY: 2,
    BI_WEEKLY: 3,
    MONTHLY: 4,
  },
};
