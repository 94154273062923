// TAGS //

require('../tags/gp-avatar.tag');
require('../tags/gp-login.tag');
require('../tags/donate-overlay/gp-donate-overlay-parent.tag');
require('../tags/gp-tax-receipt-overlay.tag');
require('../tags/gp-tax-receipt-transaction-summary.tag');
require('../tags/gp-nav.tag');
require('../tags/gp-mobile-nav.tag');
require('../tags/gp-page.tag');
require('../tags/gp-payment-methods.tag');
require('../tags/gp-organizations.tag');
require('../tags/gp-select-cause.tag');
require('../tags/gp-years.tag');
require('../tags/gp-scaffold.tag');

require('../tags/gp-charities.tag');
require('../tags/gp-charity.tag');
require('../tags/gp-campaign.tag');

require('../tags/gp-donations.tag');
require('../tags/gp-donations-drawer.tag');

require('../tags/gp-recurrings.tag');
require('../tags/gp-recurrings-drawer.tag');
require('../tags/gp-recurring-frequency.tag');
require('../tags/gp-recurring-payment-in-use.tag');

require('../tags/gp-settings.tag');
require('../tags/gp-settings-drawer.tag');

require('../tags/gp-wallet.tag');
require('../tags/gp-wallet-overlay.tag');
require('../tags/gp-wallet-drawer.tag');

require('../tags/gp-dialog.tag');
require('../tags/gp-notification-dialog.tag');
require('../tags/gp-toast.tag');

require('../tags/gp-address.tag');

require('../tags/donate-overlay/gp-donate-overlay-page-1.tag');
require('../tags/donate-overlay/gp-donate-overlay-page-2.tag');
require('../tags/donate-overlay/gp-donate-overlay-page-3.tag');
require('../tags/donate-overlay/gp-donate-overlay-payment-method.tag');
