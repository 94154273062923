const LaunchDarklyClientMock = require('./featureFlagsMock');
const ldClient = require('launchdarkly-js-client-sdk');
const envVars = require('../../../env.json');

const defaultClientOptions = {
  streaming: true,
  allAttributesPrivate: true,
};

class LaunchDarklyProject {
  constructor(params) {
    // Setup Client
    this.clientKey = params.clientKey;
    this.userKey = params.userKey;

    this.clientOptions = {
      ...defaultClientOptions,
      ...params.clientOptions,
    };

    this.user = {
      ...(params.userOptions || {}),
      key: this.userKey,
    };

    this.flags = {};
    this.flagCallbacks = {};

    // Set Callbacks
    if (params.onChange) this.onChange = params.onChange;
    if (params.onReady) this.onReady = params.onReady;

    // Set Flags and FlagCallbacks
    const flagCallbacks = params.flagCallbacks || {};

    Object.keys(params.flags).forEach((flagKey) => {
      this.flags[flagKey] = params.flags[flagKey];

      if (flagCallbacks[flagKey]) {
        this.flagCallbacks[flagKey] = flagCallbacks[flagKey];
      }
    });

    this.initializeLaunchDarkly();
  }

  initializeLaunchDarkly() {
    this.client = ldClient.initialize(
      this.clientKey,
      this.user,
      this.clientOptions,
    );

    this.client.on('change', (allChanges) => {
      Object.keys(allChanges).forEach((flagOption) => {
        const change = allChanges[flagOption];

        this.flags[flagOption] = change.current;
        if (this.flagCallbacks[flagOption]) {
          this.flagCallbacks[flagOption](change.current, change.previous);
        }
      });

      if (this.onChange) this.onChange(allChanges);
    });

    this.client.on('ready', () => {
      this.ready = true;

      Object.keys(this.flags).forEach((flagKey) => {
        this.flags[flagKey] = this.client.variation(
          flagKey,
          this.flags[flagKey],
        );
      });

      if (this.onReady) this.onReady();
    });
  }
}

class LaunchDarklyClient {
  constructor(params) {
    this.projects = {};
    Object.keys(params).forEach((projectKey) => {
      const clientParams = params[projectKey];
      this.projects[projectKey] = new LaunchDarklyProject(clientParams);
    });
  }
}

const featureFlagDefaults = {
  branding: {
    userKey: 'test',
    clientKey: envVars.LD_BRANDING_CLIENT,
    flags: {
      'toggle-paystone-branding': false,
    },
    onReady: function() {
      var customEvent = new CustomEvent('ffReady', {});
      window.dispatchEvent(customEvent);
    },
    onChange: function() {
      var customEvent = new CustomEvent('ffChanged', {});
      window.dispatchEvent(customEvent);
    },
  },
};
if (window.config.isTest) {
  window.LDClient = new LaunchDarklyClientMock(featureFlagDefaults);
} else {
  window.LDClient = new LaunchDarklyClient(featureFlagDefaults);
}
