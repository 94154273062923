

function Transaction() {

}

Transaction.prototype.fetchTransaction = function (id, params) {
  params = params || {};
  params.populate = 'organization,kiosk,cause,user';
  const query = window.buildQuery(params);
  return window.Servers.givepoint.getReq(`transaction/${id}${query}`);
};

Transaction.prototype.fetchTransactions = function (params) {
  params = params || {};
  if (!params.isApproved) params.isApproved = true;
  if (!params.populate) params.populate = 'organization,cause,kiosk,user';
  return window.Servers.givepoint.getReq(`transactions${window.buildQuery(params)}`);
};

Transaction.prototype.fetchDonations = function (params) {
  params = params || {};
  if (!params.populate) params.populate = 'organization,cause,kiosk';
  return window.Servers.givepoint.getReq(`donations${window.buildQuery(params)}`);
};

Transaction.prototype.createTransaction = function (transaction, params) {
  const query = params || {};

  // Add Timezone
  transaction.transaction.timezone = window.jstz.determine().name() || null;

  return window.Servers.givepoint.postReq(`transaction/cause/${transaction.cause}${window.buildQuery(query)}`, transaction);
};

Transaction.prototype.createTransactionWithPaymentMethod = function (transaction, paymentMethod, params) {
  const query = params || {};

  // Add Timezone
  transaction.transaction.timezone = window.jstz.determine().name() || null;

  return window.Servers.givepoint.postReq(`transaction/cause/${transaction.cause}/paymentMethod/${paymentMethod}${window.buildQuery(query)}`, transaction);
};

Transaction.prototype.fetchTransactionsByPan = function (pan, params) {
  const query = params || {};
  if (!params.populate) params.populate = 'organization,kiosk';

  return window.Servers.givepoint.getReq(`transactions/unclaimed/pan/${pan}${window.buildQuery(params)}`);
};

Transaction.prototype.downloadReceipt = function (id) {
  const options = { headers: { Accept: 'arraybuffer' } };

  // return this.server.getReq('transaction/' + id + '/receipt', { }, options);
  return window.Servers.givepoint._makeRequest('BUFFER', `transaction/${id}/receipt`, { }, options);
};

module.exports = Transaction;
