var riot = require('riot');
module.exports = riot.tag2('gp-dialog', '<div class="scrim modal-scrim {active: active}"></div> <div class="dialog-wrapper modal-wrapper {active: active}"> <div class="dialog-flex-top"></div> <div class="dialog modal-dialog"> <div class="title">{title}</div> <div class="body" ref="dialogBody"></div> <div class="buttons"> <div class="button dismiss noselect" onclick="{onDismiss}">{dismiss.title}</div> <div if="{confirm.title && confirm.action}" class="button confirm noselect" onclick="{onConfirm}">{confirm.title}</div> </div> </div> <div class="dialog-flex-bottom"></div> </div>', 'gp-dialog .dialog,[data-is="gp-dialog"] .dialog{ width: calc(100% - 40px); background: #FFF; max-width: 400px; min-width: 280px; max-height: 500px; border-radius: 6px; box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22); } gp-dialog .dialog .title,[data-is="gp-dialog"] .dialog .title{ font-size: 18px; font-weight: bold; letter-spacing: 1px; color: rgba(0, 0, 0, 0.87); text-transform: uppercase; margin: 22px 24px; } gp-dialog .dialog .body,[data-is="gp-dialog"] .dialog .body{ font-size: 16px; line-height: 1.5; text-align: left; color: rgba(0, 0, 0, 0.54); padding: 14px 24px 16px; max-height: 300px; overflow-y: auto; } gp-dialog .dialog .body .bold,[data-is="gp-dialog"] .dialog .body .bold{ font-weight: bold; color: rgba(0, 0, 0, .87); } @media screen and (-webkit-min-device-pixel-ratio: 0) { gp-dialog .dialog .body,[data-is="gp-dialog"] .dialog .body{ overflow-y: scroll; -webkit-overflow-scrolling: touch; } } gp-dialog .dialog .buttons,[data-is="gp-dialog"] .dialog .buttons{ width: 100%; height: 52px; font-size: 14px; font-weight: bold; letter-spacing: 0.5px; text-align: right; color: #2296f3; } gp-dialog .dialog .buttons .button,[data-is="gp-dialog"] .dialog .buttons .button{ display: inline-block; padding: 16px; cursor: pointer; } @media screen and (max-width: 400px) { gp-dialog .dialog,[data-is="gp-dialog"] .dialog{ width: calc(100% - 32px); } }', '', function(opts) {


    this.title   = '';
    this.body    = '';
    this.dismiss = {};
    this.confirm = {};
    this.active  = false;

    this.on('mount', function () {
      app.tags.dialog = this;
      this.update();

      app.showDialog = function (params) {

        params = params || {};

        if (!params.title || !params.body) {
          if (window.config.logs) console.log(' Error: Cannot show dialog without Title and Body');
          return;
        }

        this.title  = params.title;
        this.body   = params.body;
        this.refs.dialogBody.innerHTML = this.body;

        this.confirm         = params.confirm          || false;
        if (this.confirm) {
          this.confirm.title   = params.confirm.title  || 'GOT IT';
          this.confirm.action  = params.confirm.action || null;
        }

        this.dismiss         = params.dismiss          || {};
        this.dismiss.title   = params.dismiss && params.dismiss.title  ? params.dismiss.title  : 'GOT IT';
        this.dismiss.action  = params.dismiss && params.dismiss.action ? params.dismiss.action : null;

        document.body.addEventListener('onkeydown', this.keyDown);

        this.active = true;
        this.update();
      }.bind(this);

    }.bind(this));

    this.onConfirm = function (e) {
      if (this.confirm.action) this.confirm.action();
      this.active = false;
      this.update();
    }.bind(this);

    this.onDismiss = function (e) {
      if (this.dismiss.action) this.dismiss.action();
      this.active = false;
      this.update();
    }.bind(this);

});