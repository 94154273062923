(function onLoad(document) {
  require('./polyfills/customEventPolyfill');

  window.configConstructor = require('./config');
  window.config = new window.configConstructor();

  // Feature Flags
  require('./featureFlags/featureFlags.js');

  // Address utilities
  require('./address/address.js');

  document.addEventListener('keydown', function(e) {
    // Look for "Tab" key value 9
    if (e.which === 9 && e.target.tagName !== 'INPUT') e.preventDefault();
  });

  const Server = require('./models/server');

  // SERVER MODELS
  window.Servers = {
    account: new Server({
      api: window.config.API.ACCOUNT,
      platform: window.config.ACCOUNT_KEY,
    }),
    givepoint: new Server({
      api: window.config.API.GIVEPOINT,
    }),
    media: new Server({
      api: window.config.API.MEDIA,
    }),
  };

  require('riot');
  require('./models/sockets');

  // TAGS
  require('./appTags.js');

  // APP DATA - Other dependencies below depend on this happening first (ie: router)
  window.app = require('./appData.js');

  // SERVER MODELS
  const Account = require('./models/account');
  const Cause = require('./models/cause');
  const Organization = require('./models/organization');
  const Transaction = require('./models/transaction');
  const Recurring = require('./models/recurring');
  const Verification = require('./models/verification');

  window.Account = new Account();
  window.Cause = new Cause();
  window.Organization = new Organization();
  window.Transaction = new Transaction();
  window.Recurring = new Recurring();
  window.Verification = new Verification();

  // LIBRARIES
  window._ = require('lodash');
  window.fileSaver = require('file-saver');
  window.List = require('./models/list');
  window.moment = require('moment');
  window.route = require('riot-route');
  window.jstz = require('jstz');

  // DETECT TOUCH DEVICE
  window.isTouchDevice = !!(
    'ontouchstart' in window || navigator.msMaxTouchPoints
  );

  // IE11 Polyfill
  require('./polyfills/svg4everybody')();

  // GLOBAL FUNCTIONS
  require('./globalFunctions.js');

  // ROUTER - This also mounts the first tag
  require('./appRouter.js');
})(document);
