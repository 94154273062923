

function Sockets(params) {
  Pusher.log = function (message) {
    if (window.config.logs) console.log('PUSHER LOG - ', message);
  };

  this.key = window.config.PUSHER_KEY;
  this.endpoint = `${window.config.API}pusher/auth`;

  this.params = params;

  this.connect();
}

Sockets.prototype.connect = function (params) {
  this.pusher = new Pusher(
    this.key,
    {
      encrypted: true,
      authEndpoint: this.endpoint,
      auth: {
        headers: {
          Authorization: `bearer ${this.params.server.token.accessToken}`,
        },
      },
    },
  );

  this.privateChannel = this.pusher.subscribe(`private-${this.params.userId}`);
  this.presenceChannel = this.pusher.subscribe(`presence-${this.params.userId}`);
  this.unavailable = false;

  this.pusher.connection.bind('state_change', (states) => {
    this.state = states;

    // UNAVAILABLE //
    if (this.state.current === 'unavailable') {
      window.setOnlineStatus(false);
      this.unavailable = true;
    }

    // CONNECTED TO CONNECTING //
    if (this.state.previous === 'connected' && this.state.current === 'connecting') {
      this.unavailable = true;
      window.setOnlineStatus(false);
    }

    // CONNECTING //
    if (this.state.current === 'connecting') {
    }

    // UNAVAILABLE TO CONNECTED //
    if (this.unavailable && this.state.current === 'connected') {
      window.setOnlineStatus(true);

      document.dispatchEvent(new CustomEvent('reconnected', {
        detail: { name: 'reconnected' },
      }));

      this.unavailable = false;
    }
  });

  this.privateChannel.bind('update', (data) => {
    document.dispatchEvent(new CustomEvent('liveupdate', {
      detail: { data: data },
    }));
  });
};

Sockets.prototype.disconnect = function () {
  this.pusher.disconnect();
};

module.exports = Sockets;
