let Promise = require('bluebird'),
  request = require('superagent'),
  ServerError = require('./serverError'),
  AccessToken = require('./token.js'),
  riot = require('riot');

function Server(params) {
  this.URLROOT = params.api;

  this.hasStorage = this.checkStorageSupport();

  this.getReq = this._makeRequest.bind(this, 'GET');
  this.postReq = this._makeRequest.bind(this, 'POST');
  this.putReq = this._makeRequest.bind(this, 'PUT');
  this.delReq = this._makeRequest.bind(this, 'DELETE');
  this.postBufferReq = this._makeRequest.bind(this, 'POSTBUFFER');
  this.isConnecting = false;
  this.token = new AccessToken({ server: this });
  this.isReauthenticating = false;
  this.storedCalls = [];
  this.platform = params.platform || null;
  this.apikey = params.apikey || null;
}

Server.prototype.checkStorageSupport = function() {
  try {
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
};

Server.prototype.connect = function(email, password) {
  // step1 - Get clientID, and clientSecret
  this.isConnecting = true;
  return this.token
    .create(email, password)
    .bind(this)
    .then(function(data) {
      this.isConnecting = false;
      updateTokens(this);
      return Promise.resolve(data);
    })
    .catch(function(error) {
      if (window.config.logs) console.log('CONNECT ERROR - ', error);

      this.isConnecting = false;
      return Promise.reject(error);
    });
};

Server.prototype.reauthenticate = function() {
  return this.token
    .refresh()
    .bind(this)
    .then(function() {
      updateTokens(this);
      return Promise.resolve();
    })
    .catch((e) => Promise.reject(e));
};

Server.prototype.canReconnect = function() {
  if (this.token.refreshToken && this.token.refreshToken !== 'undefined')
    return true;

  return false;
};

Server.prototype.getRequest = function(url, data, options) {
  return request
    .get(url)
    .set(options.headers)
    .query(data);
};

Server.prototype.getBufferRequest = function(url, data, options) {
  return request
    .get(url)
    .responseType('arraybuffer')
    .set(options.headers)
    .query(data);
};

Server.prototype.postBufferRequest = function(url, data, options) {
  return request
    .post(url)
    .responseType('arraybuffer')
    .set(options.headers)
    .send(data);
};

Server.prototype.postRequest = function(url, data, options) {
  return request
    .post(url)
    .set(options.headers)
    .send(data);
};

Server.prototype.putRequest = function(url, data, options) {
  return request
    .put(url)
    .set(options.headers)
    .send(data);
};

Server.prototype.delRequest = function(url, options) {
  return request.del(url).set(options.headers);
};

Server.prototype._makeRequest = function(
  type,
  route,
  data,
  options,
  serverRequest,
) {
  const that = this;
  serverRequest = serverRequest || false;

  return new Promise((resolve, reject) => {
    that.handleRequest(
      type,
      route,
      data,
      options,
      serverRequest,
      (err, data) => {
        if (err) return reject(err);
        return resolve(data);
      },
    );
  });
};

Server.prototype.handleRequest = function(
  type,
  route,
  data,
  options,
  serverRequest,
  cb,
) {
  let url = this.URLROOT + route;
  var options = options || {};
  let req;

  if (!options.headers) options.headers = { Accept: 'application/json' };
  if (this.platform) options.headers['X-Platform'] = this.platform;
  if (this.apikey) options.headers.apikey = this.apikey;

  if (this.token.accessToken)
    options.headers.Authorization = `bearer ${this.token.accessToken}`;

  if (window.config.isTest) {
    if (!route.includes('?')) {
      url += '?';
    } else {
      url += '&';
    }

    url += 'isTest=true';
  }

  // only used for postbuffer error handling right now, the rest is from parsed json
  let allow_extended_error_header = false;

  switch (type) {
    case 'GET':
      req = this.getRequest(url, data, options);
      break;
    case 'BUFFER':
      req = this.getBufferRequest(url, data, options);
      break;
    case 'POSTBUFFER':
      req = this.postBufferRequest(url, data, options);
      allow_extended_error_header = true;
      break;
    case 'POST':
      req = this.postRequest(url, data, options);
      break;
    case 'PUT':
      req = this.putRequest(url, data, options);
      break;
    case 'DELETE':
      req = this.delRequest(url, options);
      break;
  }

  if (window.config.logs) console.log('DATA - url - %s ', type, url, data);

  req.end((err, res) => {
    let error;
    if (window.config.logs) console.log('RESPONSE - url - ', url, res);

    if (!res) {
      error = new ServerError({
        code: 40030,
        message: 'Unable to contact server. Please try again later.',
      });
    } else if (res.body && res.body.error) {
      error = new ServerError(res.body.error, res.error.status);
    } else if (
      err ||
      (res.headers['x-error-code'] && allow_extended_error_header)
    ) {
      if (type == 'POSTBUFFER') {
        const decodedString = String.fromCharCode.apply(
          null,
          new Uint8Array(res.body),
        );
        res.error = JSON.parse(decodedString).error;
      }

      error = new ServerError(res.error, res.error.status);

      if (!res.body && res.text) {
        error.message = res.text;
      }
    }

    // Check for any errors
    if (error) {
      if (error && error.code === 40004) {
        app.showToast('Server Error - Please try again.', 'error', 10000, true);
        return Promise.reject();
      } else if (error && error.code === 40005) {
        forceSignout();
        return Promise.reject();
      } else if (error && error.code === 40030) {
        cb(error, {});
        return Promise.reject();
      } else if (
        !this.isConnecting &&
        (res.status === 401 ||
          error.code === error.CODES.ACCESS_TOKEN_NOT_FOUND ||
          error.code === 40021 ||
          error.code === error.CODES.ACCESS_TOKEN_EXPIRED)
      ) {
        if (!serverRequest) {
          this.addStoredCall(type, route, data, options, serverRequest, cb);
        } else if (this.isReauthenticating) {
          forceSignout();
          return;
        }

        this.isReauthenticating = true;

        Promise.delay(200)
          .bind(this)
          .then(function() {
            return this.reauthenticate().bind(this);
          })
          .then(function() {
            this.callStoredCalls();
          });
      } else {
        cb(error, {});
      }
    } else {
      let body;

      if (res.body) body = res.body;
      else if (res.text) body = res.text;

      if (window.config.logs)
        console.log('BODY - ', body, res, req, req.getAllResponseHeaders);

      cb(undefined, body);
    }
  });
};

Server.prototype.addStoredCall = function(
  type,
  route,
  data,
  options,
  serverRequest,
  cb,
) {
  this.storedCalls.unshift({
    type: type,
    route: route,
    data: data,
    options: options,
    serverRequest: serverRequest,
    cb: cb,
  });
};

Server.prototype.callStoredCalls = function() {
  while (this.storedCalls.length > 0) {
    const call = this.storedCalls.pop();

    this.handleRequest(
      call.type,
      call.route,
      call.data,
      call.options,
      call.serverRequest,
      call.cb,
    );
  }
};

Server.prototype.clear = function() {
  clearServers(this);
  this.token.clear();
  updateTokens(this);
  this.clearStorage();
};

Server.prototype.clearStorage = function() {
  if (this.hasStorage) {
    window.localStorage.clear();
  }
};

function updateTokens(scope) {
  for (const key in window.Servers) {
    window.Servers[key].token.accessToken = scope.token.accessToken;
    window.Servers[key].token.refreshToken = scope.token.refreshToken;
  }
}

function clearServers(scope) {
  return scope
    .delReq(scope.token.route, {}, {}, true)
    .bind(this)
    .then((data) => {
      for (const key in window.Servers) {
        window.Servers[key].storedCalls = [];
      }
      return Promise.resolve(data);
    });
}

function forceSignout() {
  if (window.localStorage.accessToken || window.localStorage.refreshToken) {
    app.showToast('Please sign in to continue.', 'notify', 10000, true);
    window.route('signout');
  }
}

module.exports = Server;
